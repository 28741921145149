import React, { ReactElement } from 'react';
import { PageProps } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { LoggedIn, LoginForm, RegistrationForm } from '../components/Login';
import { Main } from '../components/Main';

const Login = ({ location }: PageProps): ReactElement => {
  const { hash } = location;
  const identity = useIdentityContext();
  const { user, urlToken } = identity;

  return (
    <Main>
      {user ? (
        <LoggedIn />
      ) : !hash && !urlToken ? (
        <LoginForm />
      ) : (
        <RegistrationForm />
      )}
    </Main>
  );
};

export default Login;
